.products {
    height: 100vh;
    padding-top: 100px;
}
a{
    text-decoration: none;
    color: black;
}
h1 {
    color: black;
    font-size: 50px;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0.50px;
    word-wrap: break-word;
    margin-top: 2%;
}

h3 {
    color: black;
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.30px;
    word-wrap: break-word;
    margin-top: 2%;
}
.productImg {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.SpecificProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productPara {
    font-size: 1.4rem;
    font-family: Montserrat;
    opacity: 0.7;
}

.d-block {
    height: 400px;
    width: 400px;
    border-radius: 16px;

}
h2{
    color: black;
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0.50px;
    word-wrap: break-word;
    margin-top: 2%;
}

#carouselExample {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 670px !important;
    z-index: 0 !important;
}
#carouselExampleIndicators{
    height: 470px !important;
    z-index: 0 !important;  
}

.But {
    width: 100px;

}

.Fproducts {
    /* background-color: #3ec9b2; */
    background-image: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );
}

.ProductHeading {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#arrow {
    color: black !important;
}

/* .carousel-control-next-icon {
    background-image: url("https://cdn-icons-png.flaticon.com/512/25/25638.png") !important;
}

.carousel-control-prev-icon {
    background-image: url("https://www.iconpacks.net/icons/2/free-arrow-left-icon-3099-thumb.png") !important;

} */

.carousel-indicators [data-bs-target] {
    background-color: black;
    margin-top: 2%;
}

#featuredProducts {
    padding-top: 100px;
}
@media screen and (max-width : 1564px) and (min-width : 1290px) {
    .d-block{
        height: 300px;
        width: 300px;
    }
    h3{
        font-size: 20px;
    }
    .productPara{
        font-size: 1rem;
    }
    
}
@media screen and (max-width : 1290px) {
    .d-block{
        height: 200px;
        width: 200px;
    }
    h3{
        font-size: 15px;
    }
    .productPara{
        font-size: 1rem;
    }
}