.products {
    height: 100vh;
    padding-top: 100px;
}

h1 {
    color: black;
    font-size: 3rem;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0.50px;
    word-wrap: break-word;
    margin-top: 2%;
}

.productImg {

    display: flex;
    flex-direction: row;
    justify-content:space-around;
    
}

.SpecificProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 470px;
}


.d-block {
    height: 400px;
    width: 400px;
    border-radius: 16px;

}


#carouselExampleDark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 670px!important;
    z-index: 0 !important;
}
/* #IndicatioN{
    height: 470px !important;
    z-index: 0 !important;  
} */

.But {
    width: 100px;

}

.Fproducts {
    background-color: #D9F2EE;
    height: 670px;

}

.ProductHeading {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#arrow {
    color: black !important;
}

.carousel-control-next-icon {
   filter: invert(3) !important;
}
.carousel-control-prev-icon {
    filter: invert(3) !important;

}

.carousel-indicators [data-bs-target] {
    background-color: black;
    margin-top: 2%;
}

#featuredProducts {
    padding-top: 100px;
}

.card-img-top {
max-width: 100px;
border-radius: 50%;
margin: 15px auto 0;
box-shadow: 0 8px 20px -4px #95abbb;
width: 100px;
height: 100px;
}

.card-img-top {
    max-width: 100%;
    height: 90px;
    width: 90px;
}

.card {
    width: 21rem;
    height: 30rem;
}

.position{
    font-size: 17px;
}
.company{
    font-size: 17px;
}

@media screen and (max-width : 1564px) and (min-width : 1290px) {
    .d-block{
        height: 300px;
        width: 300px;
    }
  
    
}
@media screen and (max-width : 1290px) {
    .d-block{
        height: 200px;
        width: 200px;
        /* padding-top: 20px; */
    }
    .productImg {
        height: 57vh;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        font-size: 13px;
    }
    #carouselExampleDark{
        height: 540px !important;
    }
    .But {
        width: 40px;
    
    }

    .SpecificProduct{
        height: 395px;
    }
    
    .card-text{
        padding-top: 8px !important;
    }

    .card{
        height: 27rem;
    }
   
}