#top{
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    height: 105vh;
}
.productDetails{
    display: flex;
    flex-direction: row;
    margin-bottom: 150px;
    width: 78%;
    position: relative;
    right: 0;
    align-items: center;
    justify-content: center;
}
.click:hover{
    background-color: white;
    color: black;
    border-left: 50%;
}
.vyvy{
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}
.hfhv{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 100px;
}
aside {
    color: #fff;
    width: 250px;
    padding-left: 20px;
    /* height: 79.5vh; */
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    border-top-right-radius: 80px;
    z-index: 0;
  }
  
  aside .hovereffect {
    font-size: 12px;
    color: #fff;
    display: block;
    padding: 12px;
    padding-left: 30px;
    text-decoration: none;
    -webkit-tap-highlight-color:transparent;
  }
  
  aside .hovereffect:hover {
    color: #3f5efb;
    background: #fff;
    outline: none;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  aside .hovereffect i {
    margin-right: 5px;
  }
  
  aside .hovereffect:hover::after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 20px 0 0 #fff;
  }
  
  aside .hovereffect:hover::before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 38px;
    right: 0;
    height: 35px;
    width: 35px;
    border-top-right-radius: 18px;
    box-shadow: 0 -20px 0 0 #fff;
  }
  
  aside p {
    margin: 0;
    padding: 40px 0;
  }
  
  
  
#imagesDiv{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 40px;
    
}
.imgBt{
    border: 0;
    background-color: white;
    margin: 4px 0;
}
#mainImage{
    width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
    
}
#detailsDiv{
    width: 50%;
    padding-top: 50px;
}
#imageButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.productImage{
    width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 30px;
    /* border-radius: 30px; */
}
/* @media screen and (max-width : 1176px) {
    .productDetails{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #imagesDiv{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #imageButtons{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    #detailsDiv{
        text-align: center;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .spanSex{
        /* text-align: center;
        padding: 0px 100px; */
        /* display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px !important;
    }
    .buttonDiv{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .yoyo{
        display: flex;
        justify-content: center;
    }
} */ 
@media screen and (max-width : 1500px) and (min-width : 1100px) {
    p div b{
        font-size: 0.9rem !important;
    }
    p div {
        font-size: 0.9rem !important;
    }
    .contentDiv{
        font-size: 0.9rem !important;
    }
    #mainImage{
        width: 300px !important;
        height: 300px !important;
    }
    .bold{
        font-size: 0.8rem !important;
    }
    .Span{
        font-size: 0.8rem !important;
    }
}
@media screen and (max-width: 1100px){
    #top{
        padding-top: 100px;
        display:block;
        
        height: 170vh;
    }
    .productDetails{
        width: 100%;
        margin:auto;
    }
    .hfhv{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }
    #imagesDiv{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }
    #imageButtons{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    #detailsDiv{
        text-align: center;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0;
    }
    .spanSex{
        text-align: center;
        padding: 0px 100px; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px !important;
    }
    .buttonDiv2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .yoyo{
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width : 673px) {
    #top{
        padding-top: 100px;
        display:block;
        height: 150vh;
    }
    .hfhv{
        padding-top: 50px;
    }
    #mainImage{
        height: 300px;
        width: 300px;
    }
    .miniButtons{
        height: 30px !important;
        width: 30px !important;
    }
    .contentDiv{
        width: 300px !important;
    }
    .yoyo{
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width : 401px){
    #top{
        padding-top: 100px;
        display:block;
        height: 200vh;
    }
    .hfhv{
        padding-top: 50px;
    }
    .contentDiv{
        width: 200px !important;
        font-size: 0.8rem !important;
    }
    p div b{
        font-size: 0.8rem !important;
    }
    .Span{
        font-size: 0.8rem !important;
    }
    .yoyo{
        display: flex;
        justify-content: center;
    }

}

