
.Accolade{
    display: flex;
    flex-direction: column;
    width: 100vw; 
    justify-content: center;
    align-items: center;  
    margin-bottom: 25px;
    padding-bottom: 25px;
    background-color:rgba(177, 219, 238, 0.918);
}
.iMageCont{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.iMageCont2{
    display: flex;
    flex-direction: column;
    
}
.iMageCont3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}
@media screen and (max-width : 1024px) and (min-width : 900px) {
    .iMageCont{
        width : 1010px !important;
    }
    .imge1{
        width: 350px !important;
        height: 500px !important;
    }
    .imge2{
        width: 550px !important; 
        height: 90px !important; 
    }
    .imge3{
        width: 100px !important; 
        height: 100px !important; 
    }
    .imge4{
        width: 300px !important;
        height: 400px !important;  
    }
    .imge5{
        width: 100px !important;
        height: 100px !important;  
    }
}
@media screen and (max-width : 900px) {
    .iMage1 {
        display: none;
    }
    .imge2{
        width : 300px !important;
        height: 70px !important;
    }
    .iMageCont2{
        justify-content: center;
        align-items: center;
    }
    .iMageCont3{
        flex-direction: column;
    }
    .imge3{
        margin-top: 10px;
        display: none;
    }
    .imge4{
        margin-top: 10px;
    }
    .imge5{
        margin-top: 10px;
        display: none;
    }
}