.aboutus{
    height: 690px;
    padding-top: 100px;
}
.abtdes{
  font-size: 1.2rem;
}
.BTN {
    width: 300px;
    height: 70px;
    border-radius: 40px;
    display: block;
    background: #00aeef;
    margin-left: 0;
    border: none;
    z-index: 0;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}
/* .abtimage{
    height: 100%;
    width: 70%;
} */
.row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    animation: gridimg1 3s infinite ease-in-out;
    animation: gridimg2 6s infinite ease-in-out;
    animation: gridimg3 9s infinite ease-in-out;
    animation: gridimg4 12s infinite ease-in-out;
  }
   


  .flip-box {
    background-color: transparent;
    width: 500px;
    height: 400px;
    /* margin-top: 50px; */
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-box-front {
    background-color: #bbb;
    color: black;
  }
  
  /* Style the back side */
  .flip-box-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
  }
  
  .column {
    flex: 50%;
    padding: 0 4px;
  }
  
  /* .column img {
    margin-top: 8px;
    vertical-align: middle;
  } */
    

#A2 { top: 0;   left: 0;}   
    @keyframes gridimg1{
        100%{ background: orange}
    }
#A3 { top: 0;   left: 50%; ;}
    @keyframes gridimg2{
        100%{ background: blue;}
       
    }
#A4 { top: 50%; left: 0; }   
    @keyframes gridimg3{
        100%{ background: red;}
       
    }
#A5 { top: 50%; left: 50%; }
    @keyframes gridimg4{
         100%{background: green;}
      
    }

@keyframes gridimg {
    0%{
   
    }
    33%{
        
    }
    66%{
      
    }
    100%{
       
    }
}
@media screen and (max-width : 1260px) {
    .aboutus{
      height:500px;
    }
    .abtimage{
        display: none !important;
    }
    .abttext{
        width: 100% !important;
        padding: 5%;
        margin: 0 !important;
    }
    .abtheading{
        text-align: center !important;
    }
    .abtdes{
      font-size: 1.2rem;
    }
    .abtdesc{
        text-align: center !important;
        margin: auto;
    }
    
    .BTN{
        position: absolute;
        
        width: 260px;
        height: 60px;
      
    }
    .button{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

.abttext{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
@media screen and (max-width : 630px) and (min-width : 435px) {
  .aboutus{
    height: 600px;
  }
  .abttext{
    margin: 0 !important;
  }
}
@media screen and (max-width : 435px) {
  #egf{
    font-size: 1.1rem;

  }
  .aboutus{
    height: 800px;
  }
  .abtdes{
    font-size: 1.2rem;
  }
  .abttext{
    margin: 0 !important;
  }
  .aboutus{
    padding-top: 70px;
  }
}