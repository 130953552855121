.chooseus{
    /* height:500px; */
    padding-top: 65px; 
    color: #000;
    font-family: Poppins;
    
    font-style: normal;
    
    line-height: normal;
}
.fontchooseus{
    padding: 0px 5px;
    color: white;
    font-size: 22px !important;
}
.whychooseus{
    background: linear-gradient(180deg,rgba(38,67,78,0),#20404b 68.05%);
}
.whychooseus1{
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%) !important;
}
@media screen and (max-width : 1440px) and (min-width : 1248px) {
    .whychooseus{
        width: 317px !important;
        height: 400px !important;
    }
    .fontchooseus{
        font-size: 22px !important;
    }
}
@media screen and (max-width : 1248px) and (min-width : 1030px){
    .whychooseus{
        width: 200px !important;
        height: 350px !important;
    }
    .whychooseus1{
        width: 150px !important;
        height: 150px !important;
    }
    .fontchooseus{
        font-size: 16px !important;
    }
    .imagechoose{
        height: 60px !important;
    }
}
@media screen and (max-width:1030px){
    
    #chooseUs{
        flex-direction: column !important;
    justify-content: center;
    align-items: center;
    }

}