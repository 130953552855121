.certiPage{
    height: 70vh;
    width: 100vw;
    background-color:rgb(212, 211, 210);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
p50{
    font-weight:600;
    font-size: 2rem;
}
.certiContent{
    height: 25%;
    width: 86%;
    display: flex;
text-align: center;
align-items: center;

}
.certiImg{
    width: 60vw;
    height: 35vh;
    justify-content: space-evenly;
    align-items: center;
    padding: auto;
    display: flex;
    flex-direction: row;
}
@media screen and (max-width : 1260px){
    .certiPage{
        height: 70vh;
        width: 100vw;
        background-color:rgb(212, 211, 210);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .certiContent{
        height: 66%;
        width: 95%;
        display: flex;
    text-align: center;
    align-items: center;
    
    }
    .certiImg{
        width: 90vw;
        height: 35vh;
        justify-content: space-evenly;
        align-items: center;
        padding: auto;
        display: flex;
        flex-direction: row;
        
    }
    
    
}
