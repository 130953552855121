.partners{
    height: 44vh;
    background: #F1F1F1;
    padding-top: 2.5%;
    margin-bottom: 4rem;
    margin-top: 5rem;
}
.imgPart{
    display: flex;
flex-direction: row;
}

.imgP{
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
}

.imgp{
    width: 60%;
    height: 60%;
    text-align: center;
}

@media screen and (max-width : 1260px) {
    .partners{
        height: 33vh;
    }
}