.companyprofile ::before{
    padding-top: 100px;
   
}

.ClickBT{
    margin-bottom: 2rem;
}
.IMGcont{
/* margin-top:144px ; */
width: 100vw;
height: 70vh;
/* background:url("https://saisuperpack.in/machine.jpg"); */
display: flex;
justify-content: center;
align-items: center;
padding-top: 85px;
/* color: white; */

}
.IMGcont::before{
    content: "";
    background-image: url('../images/core\ pipe\ 9.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    opacity: 0.8;
}
.ProfileText{
width: 895px;
height: 167px;
font-family: poppins;
font-weight: 900;
font-size:5rem ;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}

.PrFULL{
    /* margin-top: 100px; */
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 4rem;
    margin-top: 3rem;
    
}
.PrIMG{
    width: 50%;
}
.BUTT{
    height:67px ;
    width: 447px;
    border-radius:75px ;
    display: block;
    background: #00aeef;
    border: none;
    text-align: center;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}
.PrTEXT{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: #5B5B5B;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    /* padding: 20px; */
}

.CompanyLoc{
    /* height: 72vh; */
    width: 100vw; 
}

.Loc{
    width:100vw ;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.LocText{
    font-weight: 600;
    font-size: 2.6rem;
    text-align: center;
    padding-bottom: 50px;
}

.AddDetails{
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 2rem; 
   width: 30%;
   align-items: flex-start; 
}

.Companypro{
    display: flex;
    /* flex-direction: column; */
    justify-content:space-around;
    margin: 50px;
}

.AddMap{
    width: 28%; 
}

#NaMe{
    display: flex;
    width: 30vw;
    height: 12vh;
    /* justify-content: center; */
    gap: 1rem;
    /* margin-right: 4%; */
}

#UsEr{
    display: flex;
    flex-direction: column; 
}

#PHN{
    width:30vw ;
    height: 9vh;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 1rem;
    /* margin-left: 3%; */
}
#ADDlogo{
    display: flex;
    flex-direction: row;
    width:30vw ;
    height: 12vh;
    /* justify-content: center;  */
    gap: 1rem; 
    /* margin-left: 19%; */
}
#CompADD{
    display: flex;
    flex-direction: column;
    width: 90%;
}
p1{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.25px;
    word-wrap: break-word;
}
p2{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.25px;
    word-wrap: break-word;
}
p3{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.25px;
    word-wrap: break-word;
}
span{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.25px;
    word-wrap: break-word;
}
p4{
    font-weight: 600;
    font-size: 1.2rem;
}
p5{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    width: 100%;
}
p6{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    width: 100%;
}
h5{
    color: black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.25px;
    word-wrap: break-word;

}
.orderContent{
    height: 85vh;
    width: 100vw;
    /* padding: auto 0; */
}
.OrderDR{
    height: 71vh;
    width: 75vw;
    margin: auto;
    /* padding: 0 auto ; */
    background-color: #F9F6F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9rem 0rem;
}
p11{
    color: black;
font-size: 22px;
font-family: Montserrat;
font-weight: 600;
line-height: 36px;
letter-spacing: 0.25px;
word-wrap: break-word;
margin: 1rem;
margin-bottom: 0rem;
}
p12{
    color: black;
font-size: 22px;
font-family: Montserrat;
font-weight: 600;
line-height: 36px;
letter-spacing: 0.25px;
word-wrap: break-word;
margin: 1rem;
margin-top: 0.2rem;
margin-bottom:1.8rem ;
}
p13{
    color: black;
    font-size: 27px;
    font-family: Montserrat;
    font-weight: 600;
    word-wrap: break-word;
    margin: 1rem;
}

.MemberBTT1{
    height:67px ;
    width: 55%;
    border-radius:75px ;
    display: block;
    background: #00aeef;
    border: none;
    text-align: center;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    /* font-size: 1.3rem; */
    
}
.MemberBTT{
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-top: 3%;
    margin-bottom: 5%;
    height:15% ;
    width: 70%;
    justify-content: space-between;
    gap: 2rem;
}
.MemberBTT2{
    height:67px ;
    width: 55%;
    border-radius:75px ;
    display: block;
    background: #00aeef;
    border: none;
    text-align: center;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}
.MemberBTT3{
    height:67px ;
    width: 236px;
    border-radius:75px ;
    display: block;
    background: #00aeef;
    border: none;
    text-align: center;
    margin-top: 5%;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}
@media screen and (max-width : 1260px) {
  .PrFULL{
    /* height: 1500px; */
    margin: 1.6rem;
  }
  .ProfileText{
    height: 278px;
  }
    .PrTEXT{
    width: 100% !important;
    padding: 5%;
    text-align: justify !important;
    margin: auto;
    align-items: center;
  }
  .PrIMG{
    display: none !important;
  }
  .BUTT{
    height:60px ;
    width: 290px;
    border-radius:75px ;
    display: block;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    border: none;
    text-align: center;
}
.atag{
    font-size: 0.95rem !important;
}
.CompanyLoc{
    height: 311vh;
    width: 100vw;
    display: flex;
    flex-direction: column
   
}
.AddMap{
    height: 80vh !important;
    width: 80% !important;
    border-radius: 13px;
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    /* margin: auto; */
}
.Companypro{
    height: 105vh;
    width: 100vw;
   display: flex;
   flex-direction: column;
   /* justify-content: space-between; */
   align-items: center!important;
   margin: auto;
   padding: auto;
   gap: 5rem;
   margin-top: 27%;
}
.AddDetails{
    width: 100vw;
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

}
.LocText{
    padding-bottom: 0px;
}
#PHN{
    padding-left: 15px;
    width:96vw ;
    height: 9vh;
    justify-content: flex-start;
    margin: 0;
}
#NaMe{
    padding-left: 20px;
    height: 11vh;
    width: 100vw;
    justify-content: flex-start;
    margin: 0;
}
#ADDlogo{
    height: 30vh;
    width: 100vw;
    justify-content: flex-start;
    margin-left: 4vw;
    padding-left: 15px;
}
p13{
    text-align: center;
}
p6{
    text-align: justify;
    width: 77%;
}

.MemberBTT1{
    height:55px ;
    width: 80%;
    border-radius:75px ;
    display: block;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    border: none;
    text-align: center;
    margin-bottom: 5%;
    /* font-size: 1.3rem; */
    /* font-size: 0.5rem !important; */
    
}
.MemberBTT{
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: 3%;
    margin-bottom: 2%;
    height:40% ;
    width: 100%;
    justify-content: space-between;
    gap: 1.5rem !important;
}
.MemberBTT2{
    height:55px ;
    width: 67%;
    border-radius:75px ;
    display: block;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    border: none;
    text-align: center;
}
.MemberBTT3{
    height:40px ;
    width: 90%;
    border-radius:75px ;
    display: block;
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
    border: none;
    text-align: center;
    margin-top: 5%;
}
.orderContent{
    height: 111vh;
}
.OrderDR{
    height: 103vh;
    width: 83vw;
}
.ClickBT{
    width: 44%;
}
    }
