.homePage {
    height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background: linear-gradient(250deg,rgba(38,67,78,0),#20404ba8 68.05%)
}
#fb:hover{
  
    background-clip: text;
    -webkit-background-clip: text;
    cursor: pointer;
    color: #00aeef;   
    background-color: #00aeef;
  }
.homePage ::before{
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    height: 90%;
    z-index: -1;
    animation: change 15s infinite ease-in-out;
}
.buttonDiv{
    width: 700px;
    margin-left: 80px;
}
.BT {
    width: 300px;
    height: 60px;
    border-radius: 40px;
    display: block;
    background: #00aeef;
    /* margin: auto; */
    border: none;
    text-align: center;
    
    background-image: radial-gradient( circle farthest-corner at 0.2% 0.5%,  rgba(68,36,164,1) 3.7%, rgba(84,212,228,1) 92.7% );
}

.atag {
    display: block;
    margin: auto;
    color: white;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;

}

.hometext {
    color: white;
    font-family: Montserrat;
    font-size: 5rem;
    /* font-size: 50px; */
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0.01em;
    text-align: left;
    width: 700px;
    height: 224px;
    margin: 80px;
    padding: 10px;
}
.hometext::after{
    content: "Pack, Protect, Prosper";
    line-height: 88px;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: change2 15s infinite ease-in-out;
}
.homecontain{
    width : 98vw;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    margin-top: -100px;
    /* margin-top: -100px; */
}
@keyframes change {
    0%{
        background-image: url('../images/3-4-850x590.jpg');
    }
    33%{
        background-image: url('../images/Core\ Pipe\ 1.jpg');
    }
    66%{
        background-image: url('../images/core\ pipe\ 5.jpg');
    }
    100%{
        background-image: url('../images/3-4-850x590.jpg');
    }
}
@keyframes change2 {
    0%{
        content: "Pack, Protect, Prosper";
        opacity: 1;
    }
    17%{
        opacity: 0;
    }
    34%{
        content: "Your Cargo, Our Commitment";
        opacity: 1;
    }
    51%{
        opacity: 0;
    }
    68%{
        content: "Handling Tomorrow, Today";
        opacity: 1;
    }
    85%{
        opacity: 0;
    }
    100%{
        content: "Pack, Protect, Prosper";
        opacity: 1;
    }
}
@media screen and (max-width : 1440px){
    .homePage{
        padding-top: 80px;
    }
    .homePage ::before{
        top: 80px;
    }
}
@media screen and (max-width : 1080px) and (min-width : 915px) {
    .hometext{
        font-size: 2rem;
        margin: 50px;
        width: 600px;
    }
    .atag{
        font-size: 1.3rem;
    }
    .buttonDiv{
        margin-left: 50px;
    }
    .BT{
        width: 223px;
        height: 48px;
    }
}
@media screen and (max-width : 915px) and (min-width : 435px) {
    .hometext{
        font-size: 1.9rem;
        margin: 0px;
        height: 130px;
        width: 88vw;
        line-height: 30px;
        margin-bottom: 0px;
        padding-left: 30px;
    }
    .buttonDiv{
        margin-left: 40px;
    }
    .homePage{
        padding-top: 80px;
    }
    .homePage ::before{
        top: 80px;
    }
    .atag{
        font-size: 1rem;
    }
    .BT{
        width: 209px;
        height: 45px;
    }
}
@media screen and (max-width : 435px) {
    .hometext{
        font-size: 1.7rem;
        margin: 0px;
        height: 130px;
        width: 88vw;
        line-height: 30px;
        margin-bottom: 0px;
        padding-left: 30px;
    }
    .hometext::after{
        line-height: 45px;
    }
    .atag{
        font-size: 0.7rem;
        line-height: 0px;
    }
    .buttonDiv{
        margin-left: 30px;
    }
    .BT{
        width: 165px;
        height: 38px;
    }
    .homePage{
        
        padding-top: 80px;
    }
    .homePage ::before {
        
        top: 80px;
    }

}