.logoimage{
    /* width: 200px; */
    height: 60px;
    object-fit: contain;
}
.nav-items{
    font-family: Montserrat !important;
}
nav{
    width: 100vw;
    font-size: 1rem;
    height: 100px !important;
    position: fixed !important;
    background-color: white !important;
    z-index: 2;
}
li{
    margin: 20px;
}
#navitems{
    margin: 0px !important; 
    color: #000000 !important;
    font-size: 1.3rem;
    /* font-size: 22px; */
    font-family: Montserrat;
    font-weight: 600 !important;
    letter-spacing: 0.22px;
    word-wrap: break-word;
    z-index: 2 !important;
    /* background-color: white; */
}
@media all and (min-width: 992px) {
    
    /* .nav-link{width: 160px;} */
    nav{height: 90px; display: flex; justify-content: space-between;width: 100vw;}
	.navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }
	/* .navbar .nav-item:hover .nav-link{ color: white; background-color: gray; border-radius: 10px;} */
	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
}
@media screen and (max-width : 1440px) and (min-width : 1220px){
    nav{
        height: 80px !important;
    }
    #navitems {
        font-size: 1rem !important;
    }
}
@media screen and (max-width : 1220px) and (min-width :1174px) {
    #navitems {
        font-size: 0.9rem !important;
    }
}
@media screen and (max-width : 1174px) and (min-width :1030px) {
    #navitems {
        font-size: 0.8rem !important;
    }
}
@media screen and (max-width : 1030px) and (min-width :991px) {
    #navitems {
        font-size: 0.8rem !important;
    }
}
@media screen and (max-width : 900px) {
    #navitems{
        font-size: 1rem !important;
    }
    .logoimage{
        width: 150px;
        height: 60px;
    }
    nav{
        height: 70px !important;
    }
}